<template>
  <v-select
    v-bind="$attrs"
    v-on="$listeners"
    :items="items"
    item-text="text"
    item-value="value"
  >
  </v-select>
</template>

<script>
export default {
  data: () => ({
    items: [
      {
        text: "Public",
        value: "public"
      },
      {
        text: "Private",
        value: "private"
      }
    ]
  })
};
</script>
